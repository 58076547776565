import { useTranslation } from "next-i18next";
import PropTypes from "prop-types";
import { getSize } from "../../Product/lib/product-utils";
import { formatSize } from "@lib/formatString";
import styles from "./styles/lineitem.module.css";

export function Size({ variant }) {
  const { t } = useTranslation("cart");
  const size = getSize(variant);

  if (size) {
    return (
      <li className={styles.listItem}>
        <span>{t("size-label")}:</span>
        <span>{formatSize(size)}</span>
      </li>
    );
  }

  return null;
}

Size.propTypes = {
  variant: PropTypes.object.isRequired,
};
