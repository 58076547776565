import Link from "next/link";
import PropTypes from "prop-types";
import { getShopifyNextImage } from "@lib/getImages";
import styles from "./ProductCard.module.css";

const ImageStack = ({ selectedProduct, collection }) => {
  // if Image as a default mens and images for the card.
  try {
    if (
      selectedProduct.mens_image !== null &&
      selectedProduct.mens_image.image &&
      collection &&
      collection.includes("men")
    ) {
      selectedProduct.images.unshift({
        w800: selectedProduct.mens_image.image.originalSrc,
        card: selectedProduct.mens_image.image.originalSrc,
        placeholder: selectedProduct.mens_image.image.originalSrc,
        altText: selectedProduct.images[0].altText,
      });
      selectedProduct.images[1] = selectedProduct.images[3];
    }

    if (
      selectedProduct.womens_image !== null &&
      selectedProduct.womens_image.image &&
      collection &&
      collection.includes("women")
    ) {
      selectedProduct.images.unshift({
        w800: selectedProduct.womens_image.image.originalSrc,
        card: selectedProduct.womens_image.image.originalSrc,
        placeholder: selectedProduct.womens_image.image.originalSrc,
        altText: selectedProduct.images[0].altText,
      });
      selectedProduct.images[1] = selectedProduct.images[3];
    }

    return (
      <div className={styles.imageContainer}>
        <picture>
          <source srcSet={selectedProduct.images[0].w800} media="(min-width: 75em)" />
          <source srcSet={selectedProduct.images[0].card} media="(min-width: 45em)" />
          <source srcSet={selectedProduct.images[0].card} />
          <img
            className={`
          ${styles.image}
          ${styles.full}`}
            src={selectedProduct.images[0].placeholder}
            alt={selectedProduct.images[0].altText}
          />
        </picture>
        {selectedProduct.images.length > 1 && (
          <picture>
            <source srcSet={selectedProduct.images[1].w800} media="(min-width: 75em)" />
            <source srcSet={selectedProduct.images[1].card} media="(min-width: 45em)" />
            <source srcSet={selectedProduct.images[1].card} />
            <img
              className={`
          ${styles.image}
          ${styles.full}`}
              src={selectedProduct.images[1].placeholder}
              alt={selectedProduct.images[1].altText}
            />
          </picture>
        )}
        {/* {getShopifyNextImage(
          selectedProduct.images[0].w800,
          400,
          600,
          `(min-width: 75em) 1000, (min-width: 45em) 800, 600`,
          selectedProduct.images[0].altText,
          selectedProduct.images[0].placeholder
        )} */}
      </div>
    );
  } catch (e) {
    console.log(e);
    console.log(selectedProduct);
    return null;
  }
};

export const ImageSection = ({
  collection,
  listPosition,
  selectItem,
  query,
  specialText,
  selectedProduct,
  openBlank,
  isMobile,
}) => {
  const handleClick = () => {
    sessionStorage.setItem("scrollPosition", window.scrollY);
    selectItem();
  };

  const Images = () => {
    if (query) {
      return (
        <Link
          href={{
            pathname: "/products/[slug]",
            query,
          }}
          prefetch={false}
        >
          <a
            onClick={handleClick}
            role="link"
            tabIndex={listPosition}
            data-cy={`product-${listPosition}`}
            target={openBlank && !isMobile ? "_blank" : ""}
          >
            {specialText &&
              specialText.map((text) => (
                <p key={text} className={styles.specialText}>
                  {text}
                </p>
              ))}
            <ImageStack selectedProduct={selectedProduct} collection={collection} />
          </a>
        </Link>
      );
    }
    return (
      <a onClick={handleClick} role="link" tabIndex={listPosition}>
        {specialText &&
          specialText.map((text) => (
            <p key={text} className={styles.specialText}>
              {text}
            </p>
          ))}
        <ImageStack selectedProduct={selectedProduct} />
      </a>
    );
  };

  return (
    <div className={styles.imageSection}>
      <Images />
    </div>
  );
};

ImageSection.propTypes = {
  selectedProduct: PropTypes.shape({
    availableForSale: PropTypes.bool.isRequired,
    handle: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    images: PropTypes.array,
    options: PropTypes.array,
    productGroup: PropTypes.object,
    productType: PropTypes.string,
    variants: PropTypes.array.isRequired,
    w800: PropTypes.string,
  }).isRequired,
  listPosition: PropTypes.number,
  selectItem: PropTypes.func,
  link: PropTypes.shape({
    href: PropTypes.string,
    navigation: PropTypes.shape({
      colour: PropTypes.string,
    }),
    path: PropTypes.string,
    slug: PropTypes.shape({
      _type: PropTypes.string,
      current: PropTypes.string,
    }),
    text: PropTypes.string,
  }),
  query: PropTypes.shape({
    collection: PropTypes.string,
    colour: PropTypes.string,
    slug: PropTypes.string,
  }),
  specialText: PropTypes.array,
};

ImageSection.defaultProps = {
  listPosition: 0,
};
