import { useContext, useReducer, createContext } from "react";

const QuoteFormStateContext = createContext();
const QuoteFormStateDispatchContext = createContext();

export const ActionType = {
  OPEN_DRAWER: "@quoteFormDrawer/OPEN_DRAWER",
  CLOSE_DRAWER: "@quoteFormDrawer/CLOSE_DRAWER"
};

function quoteFormDrawerReducer(state, action) {
  switch (action.type) {
    case ActionType.OPEN_DRAWER: {
      return { ...state, isOpen: true };
    }
    case ActionType.CLOSE_DRAWER: {
      return { ...state, isOpen: false };
    }
    default: {
      state;
    }
  }
}

const initialState = {
  isOpen: false
};

function KotnSupplyQuoteFormDrawerProvider({ children }) {
  const [state, dispatch] = useReducer(quoteFormDrawerReducer, initialState);
  
  const openQuoteForm = () => {
    dispatch({ type: ActionType.OPEN_DRAWER });
    if (document) {
      document.body.style.overflow = "hidden";
    }
  };

  const closeQuoteForm = () => {
    dispatch({ type: ActionType.CLOSE_DRAWER });
    if (document) {
      document.body.style.overflow = "unset";
    }
  };

  const toggleQuoteForm = () => {
    if (state.isOpen) {
        openQuoteForm();
    } else {
        closeQuoteForm();
    }
  };

  return (
    <QuoteFormStateContext.Provider value={state}>
      <QuoteFormStateDispatchContext.Provider
        value={{ openQuoteForm, closeQuoteForm, toggleQuoteForm}}
      >
        {children}
      </QuoteFormStateDispatchContext.Provider>
    </QuoteFormStateContext.Provider>
  );
}

function useKotnSupplyQuoteFormState() {
  const context = useContext(QuoteFormStateContext);
  if (context === undefined) {
    throw new Error("Context Missing for KotnSupplyQuoteFormDrawerProvider");
  }
  return context;
}

function useKotnSupplyQuoteFormDispatch() {
  const context = useContext(QuoteFormStateDispatchContext);
  if (context === undefined) {
    throw new Error("dispatch must be used within a KotnSupplyQuoteFormDrawerProvider");
  }
  return context;
}

export { KotnSupplyQuoteFormDrawerProvider, useKotnSupplyQuoteFormState, useKotnSupplyQuoteFormDispatch };
