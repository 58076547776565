import Cookies from "js-cookie";
import { debounce } from "debounce";
import { Drawer } from "@components/Drawer";
import { CartDrawerHeader } from "./CartDrawerHeader";
import { CartDrawerContent } from "./CartDrawerContent";
import { CartDrawerFooter } from "./CartDrawerFooter";
import { trackEvent } from "@lib/track-event";
import { logError } from "@lib/logger";
import { useCheckoutState, useCheckoutDispatch } from "@hooks/useCheckout";
import { resolveSubtotal } from "./lib/resolve-subtotal";
import { resolveTotal } from "./lib/resolve-total";
import { getLoopReturnParams, createLoopReturnsCart } from "@lib/handle-loopReturns-params";
import { extractProductId } from "@lib/track-event/lib/extract-product-id";
import { useRouter } from "next/router";

function getVariantIds(checkout) {
  return checkout.lineItems.edges.map((node) => {
    return Number(extractProductId(node.node.variant.id));
  });
}
export function CartDrawer() {
  const router = useRouter();
  const { checkout, isOpen, loading } = useCheckoutState();
  const { closeCart, updateCartItem } = useCheckoutDispatch();

  const lineItems = checkout ? checkout.lineItems.edges : [];
  const isFrench = router.locale === "fr";

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let token;
      const hasLoopReturnParams = getLoopReturnParams();
      if (hasLoopReturnParams) {
        getVariantIds(checkout);
        token = await createLoopReturnsCart(getVariantIds(checkout));
      }
      trackEvent("begin_checkout", { checkout });

      if (token) {
        window.open(`https://returns.kotn.com/#/cart/v2/${token}`, "_self", "", true);
        localStorage.removeItem("loopOnstoreParams");
        return;
      }

      if (typeof window !== "undefined" && window.EF) {
        Cookies.set("eftid", window.EF.getAdvertiserTransactionId(12), {
          domain: "kotn.com",
        });
      }

      const url = isFrench ? `${checkout.webUrl}&locale=fr` : checkout.webUrl;

      window.sessionStorage.removeItem("__LSM__");
      window.open(url, "_self", "", true);
    } catch (error) {
      logError(error);
    }
  };

  const subtotal = resolveSubtotal(checkout);
  const location = Cookies.get("location") ? JSON.parse(Cookies.get("location")) : "N/A";
  const total = resolveTotal(checkout);

  return (
    <Drawer isOpen={isOpen} close={isOpen ? debounce(closeCart, 3000) : () => {}}>
      <CartDrawerHeader subtotal={subtotal} handleCloseDrawer={closeCart} />
      <CartDrawerContent
        lineItems={lineItems}
        loading={loading}
        updateItem={updateCartItem}
        location={location}
        handleCloseDrawer={closeCart}
      />

      {lineItems.length > 0 && (
        <CartDrawerFooter
          handleSubmit={handleSubmit}
          loading={loading}
          location={location}
          total={total}
        />
      )}
    </Drawer>
  );
}
