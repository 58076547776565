import { useEffect, useReducer, createContext, useContext } from "react";
import { useSession } from "next-auth/react";
import { logErrorMessage } from "@lib/logger";
import { useAccountInfo } from "@services/account/getAccountInfo";
import { useKlaviyoInfo } from "@services/account/getKlaviyoInfo";

const ERR_MSG =
  "Something went wrong while retrieving your information. Please refresh or try again.";

const AccountStateContext = createContext();
const AccountDispatchContext = createContext();

const ACCOUNT_ACTIONS = {
  SET_SHOPIFY_DATA: "@account/SET_SHOPIFY_DATA",
  SET_KLAVIYO_DATA: "@account/SET_KLAVIYO_DATA",
  SET_CUSTOMER: "@account/SET_CUSTOMER",
  SET_ADDRESSES: "@account/SET_ADDRESSES",
  SET_ORDERS: "@account/SET_ORDERS",
  SET_ERROR: "@account/SET_ERROR",
};

const initialAccountState = {
  shopifyData: {},
  klaviyoData: {},
  customer: {},
  addresses: [],
  orders: [],
  error: null,
};

function accountReducer(state = initialAccountState, action) {
  switch (action.type) {
    case ACCOUNT_ACTIONS.SET_SHOPIFY_DATA:
      return { ...state, shopifyData: action.payload };
    case ACCOUNT_ACTIONS.SET_KLAVIYO_DATA:
      return { ...state, klaviyoData: action.payload };
    case ACCOUNT_ACTIONS.SET_CUSTOMER:
      return { ...state, customer: action.payload };
    case ACCOUNT_ACTIONS.SET_ADDRESSES:
      return { ...state, addresses: action.payload };
    case ACCOUNT_ACTIONS.SET_ORDERS:
      return { ...state, orders: action.payload };
    case ACCOUNT_ACTIONS.SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export const useAccount = () => {
  const state = useContext(AccountStateContext);
  const dispatch = useContext(AccountDispatchContext);
  const { data: session } = useSession();

  const {
    data: shopifyData,
    error: shopifyError,
    isLoading,
  } = useAccountInfo(session.customerAccessToken);

  const { data: klaviyoData, error: klaviyoError } = useKlaviyoInfo(session.user.email);

  const setError = (payload) => dispatch({ type: ACCOUNT_ACTIONS.SET_ERROR, payload });
  const setShopifyData = (payload) => dispatch({ type: ACCOUNT_ACTIONS.SET_SHOPIFY_DATA, payload });
  const setKlaviyoData = (payload) => dispatch({ type: ACCOUNT_ACTIONS.SET_KLAVIYO_DATA, payload });
  const setCustomer = (payload) => dispatch({ type: ACCOUNT_ACTIONS.SET_CUSTOMER, payload });
  const setAddresses = (payload) => dispatch({ type: ACCOUNT_ACTIONS.SET_ADDRESSES, payload });
  const setOrders = (payload) => dispatch({ type: ACCOUNT_ACTIONS.SET_ORDERS, payload });

  const resolveShopifyConnection = (obj) => obj.edges.map((item) => item.node);

  const resolveOrdersData = (orders) => {
    return orders.edges.map((item) => {
      return {
        ...item.node,
        lineItems: resolveShopifyConnection(item.node.lineItems),
      };
    });
  };

  // Handle customerAcccessToken error
  useEffect(() => {
    if (!session.customerAccessToken) {
      logErrorMessage("[customerAccessToken]: Unable to retrieve customer access token");
      setError({ message: ERR_MSG });
    }
  }, [session.customerAccessToken]);

  // Set raw data
  useEffect(() => {
    if (shopifyError) {
      logErrorMessage(`[getAccountInfo]: ${shopifyError}`);
      setError({ message: ERR_MSG });
    }

    if (klaviyoError) {
      logErrorMessage(`[getKlaviyoInfo]: ${klaviyoError}`);
      setError({ message: ERR_MSG });
    }

    if (shopifyData) setShopifyData(shopifyData);
    if (klaviyoData) setKlaviyoData(klaviyoData);
  }, [shopifyData, klaviyoData, shopifyError, klaviyoError]);

  // Resolve customer data
  useEffect(() => {
    if (shopifyData?.customer.id) {
      setCustomer({
        id: shopifyData.customer.id,
        defaultAddress: shopifyData.customer.defaultAddress,
        displayName: shopifyData.customer.displayName,
        email: shopifyData.customer.email,
        phone: shopifyData.customer.phone,
        firstName: shopifyData.customer.firstName,
        lastName: shopifyData.customer.lastName,
        displayName: shopifyData.customer.displayName,
      });
    }
  }, [shopifyData?.customer]);

  // Resolve addresses data
  useEffect(() => {
    if (shopifyData?.customer.addresses) {
      const addresses = resolveShopifyConnection(shopifyData.customer.addresses);
      if (addresses) setAddresses(addresses);
    }
  }, [shopifyData?.customer.addresses]);

  // Resolve orders data
  useEffect(() => {
    if (shopifyData?.customer.orders) {
      const orders = resolveOrdersData(shopifyData.customer.orders);
      setOrders(orders);
    }
  }, [shopifyData?.customer.orders]);

  return {
    account: state,
    isLoading,
  };
};

export function AccountProvider({ children }) {
  const [state, dispatch] = useReducer(accountReducer, initialAccountState);

  return (
    <AccountStateContext.Provider value={state}>
      <AccountDispatchContext.Provider value={dispatch}>{children}</AccountDispatchContext.Provider>
    </AccountStateContext.Provider>
  );
}
