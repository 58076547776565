import { useState, useEffect, Component } from "react";
import Script from "next/script";
import { useRouter } from "next/router";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Error from "next/error";
import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import { init } from "@lib/sentry";
import { logError } from "@lib/logger";
import { GA4_TRACKING_ID, GA_TRACKING_ID, GTM_TRACKING_ID } from "@lib/gtag";
import { FB_PIXEL_ID } from "@lib/facebookPixel";
import { PINT_TRACKING_ID } from "@lib/pinterest";
import { TIKTOK_PIXEL_ID } from "@lib/tiktokPixel";
import { ContextProviders } from "../context";
import "../styles/global.css";
import "../styles/scales.css";

import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { handleLoopReturnsParam } from "@lib/handle-loopReturns-params";

init();

const queryClient = new QueryClient();

function setDiscountCode(router) {
  if (router.query.dc) {
    window.discountCode = router.query.dc;
  }
}
class MyErrorBoundary extends Component {
  state = {
    errorMessage: "",
  };
  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }
  componentDidCatch(error, info) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  logErrorToServices = logError;
  render() {
    if (this.state.errorMessage) {
      return <Error statusCode={500} title="An unexpected error occurred!" />;
    }
    return this.props.children;
  }
}

<link
  rel="preload"
  href="https://cdn.depict.ai/kotn/modern.js"
  as="fetch"
  crossOrigin="anonymous"
/>;
function MyApp({ Component, pageProps, err }) {
  const [klaviyo, setKlaviyo] = useState(false);

  const router = useRouter();

  pageProps.subscribed = klaviyo;

  useEffect(() => {
    handleLoopReturnsParam();
    const handleRouteChangeComplete = (url) => {
      document.body.style.overflow = "unset";
    };
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    // Your code here
    const location = (router.basePath === "" ? "localhost:3000" : router.basePath) + router.asPath;
    const handle = location.match(/[^/]+(?=\/$|$)/g);
    let utmSource = null;

    if (handle && handle[0].indexOf("?") !== -1) {
      const url = new URL(location);
      utmSource = url.searchParams.get("utm_source");
    }

    if (utmSource && utmSource.toLowerCase() === "email") {
      setKlaviyo(true);
    }
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, []);

  useEffect(() => {
    // Find the element by ID
    const timer = setTimeout(() => {
      const element = document.getElementById('CookiebotWidget');
      if (element) {
        element.style.display = 'none';
        return () => clearTimeout(timer);
      }
    }, 3000);
  }, []);

  setDiscountCode(router);

  return (
    <MyErrorBoundary>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <script></script>

      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_TRACKING_ID}');`,
        }}
      ></Script>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              (function(d) {
                var e = d.createElement('script');
                e.src = d.location.protocol + '//tag.wknd.ai/7423/i.js';
                e.async = true;
                d.getElementsByTagName("head")[0].appendChild(e);
              }(document));
            `,
        }}
      ></Script>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: ` 
          var ALGOLIA_INSIGHTS_SRC = "https://cdn.jsdelivr.net/npm/search-insights@4.7.0/dist/search-insights.min.js";
          !function(e,a,t,n,s,i,c){e.AlgoliaAnalyticsObject=s,e[s]=e[s]||function(){
          (e[s].queue=e[s].queue||[]).push(arguments)},i=a.createElement(t),c=a.getElementsByTagName(t)[0],
          i.async=1,i.src=n,c.parentNode.insertBefore(i,c)
          }(window,document,"script",ALGOLIA_INSIGHTS_SRC,"aa");`,
        }}
      ></Script>

      <Script
        id="gtag-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          urlSearchParams = new URLSearchParams(window.location.search);
          params = Object.fromEntries(urlSearchParams.entries());

          function getFbpCookie() {
            try {
               return /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie)[1];
            } catch (e) {
               return undefined;
            }
          }

          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GA_TRACKING_ID}', {
            send_page_view: false
          });

          gtag('config', '${GA4_TRACKING_ID}', {
            send_page_view: false,
            fbclid: params["fbclid"],
            fbp: getFbpCookie()
          });
          `,
        }}
      />
      <Script
        src="https://www.googleoptimize.com/optimize.js?id=GTM-N7FXHL2"
        strategy="afterInteractive"
      />

      {/* Global Site Tag (share a sale.js) - Share a Sale */}
      <Script src="https://www.dwin1.com/19038.js" strategy="afterInteractive" />
      {/* Global Site Tag (klaviyo.js) - Klaviyo */}
      <Script
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=LMZu4j"
        strategy="afterInteractive"
      />
      <Script
        id="klaviyo-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window._learnq = window._learnq || [];
              function klaviyo(arguments){_learnq.push(arguments);}
          `,
        }}
      />
      {/* Global Site Tag (grin-sdk.js) - Grin */}
      <Script src="https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js" strategy="lazyOnload" />
      <Script
        id="grin-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          Grin = window.Grin || (window.Grin = []);
          `,
        }}
      />
      {/* Global Site Tag (bat.bing.com/bat.js) - Bing */}
      <Script
        id="bing-js"
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"134083254"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
            `,
        }}
        strategy="afterInteractive"
      />
      {/* Global Site Tag - Referral */}
      <Script src="https://my.kotn.com/core.js" strategy="afterInteractive" />
      <Script
        id="extole-js"
        dangerouslySetInnerHTML={{
          __html: `
                      (function (c, e, k, l, a) {
                        c[e] = c[e] || {};
                        for (c[e].q = c[e].q || []; a < l.length; ) k(l[a++], c[e]);
                      })(
                        window,
                        "extole",
                        function (c, e) {
                          e[c] =
                            e[c] ||
                            function () {
                              e.q.push([c, arguments]);
                            };
                        },
                        ["createZone"],
                        0
                      );
                    `,
        }}
        strategy="afterInteractive"
      />
      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id="pixel-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${FB_PIXEL_ID}');
          `,
        }}
      />
      {/* Global Site Tag - tiktok */}
      <Script
        id="tiktok-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                !function (w, d, t) {
                  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

                  ttq.load('${TIKTOK_PIXEL_ID}');
                  ttq.page();
                }(window, document, 'ttq');
          `,
        }}
      />
      {/* Global Site Tag - Pinterest */}
      <Script
        id="pinterest-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                !function(e){if(!window.pintrk){window.pintrk = function () {
                  window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
                    n=window.pintrk;n.queue=[],n.version="3.0";var
                    t=document.createElement("script");t.async=!0,t.src=e;var
                    r=document.getElementsByTagName("script")[0];
                    r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
                  pintrk('load', '${PINT_TRACKING_ID}', {em: '<user_email_address>'});
                  pintrk('page');
              `,
        }}
      />
      {/* Mouseflow */}
      <Script
        id="mouseflow-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window._mfq = window._mfq || [];
              (function() {
                var mf = document.createElement("script");
                mf.type = "text/javascript"; mf.defer = true;
                mf.src = "//cdn.mouseflow.com/projects/92a8f70e-69d2-4b1b-855a-1db154db1712.js";
                document.getElementsByTagName("head")[0].appendChild(mf);
              })();
          `,
        }}
      />

      {/* DEPICT AI */}
      <Script
        id="depictai-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(){var n=!1;try{var e,t,o,i,d,l=document.createElement("iframe");l.src="about:blank",document.head.appendChild(l);var a=null==l||null===(e=l.contentWindow)||void 0===e||null===(t=e.String)||void 0===t||null===(o=t.prototype)||void 0===o||null===(i=o.replaceAll)||void 0===i||null===(d=i.toString)||void 0===d?void 0:d.call(i);l.parentNode.removeChild(l),a&&a.indexOf("[native code]")>-1&&(n=!0)}catch(n){}function r(n){var e=new XMLHttpRequest;e.addEventListener("load",(function(){return new Function(e.responseText)()})),e.open("GET","https://cdn.depict.ai/kotn/"+n+".js"),e.send()}r(n?"modern":"compatibility")}();
          `,
        }}
      />

      <SessionProvider
        options={{
          clientMaxAge: 0,
          keepAlive: 0,
        }}
        session={pageProps.session}
      >
        <ContextProviders>
          <QueryClientProvider client={queryClient}>
            <Component {...pageProps} err={err} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ContextProviders>
      </SessionProvider>
    </MyErrorBoundary>
  );
}

export default appWithTranslation(MyApp);
