import { useQuery } from "react-query";

const getKlaviyoPersonId = async (email) => {
  try {
    const res = await fetch("/api/getKlaviyoUserId", {
      method: "POST",
      headers: { Accept: "application/json" },
      body: email,
    });

    const result = await res.json();

    return result.body.id;
  } catch (error) {
    Sentry.captureException(error);
    return {
      statusCode: 500,
      body: String(error),
    };
  }
}

const getKlaviyoUser = async (email) => {
  const id = await getKlaviyoPersonId(email);

  try {
    const res = await fetch("/api/getKlaviyoProfile", {
      method: "POST",
      headers: { Accept: "application/json" },
      body: id,
    });

    const result = await res.json();

    return result.body;
  } catch (error) {
    Sentry.captureException(error);
    return {
      statusCode: 500,
      body: String(error),
    };
  }
}

export function useKlaviyoInfo(email) {
  return useQuery(
    ["getKlaviyoInfo", email],
    () => getKlaviyoUser(email),
    {
      enabled: !!email,
      refetchOnWindowFocus: false
    }
  );
}
