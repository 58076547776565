import { useTranslation } from "next-i18next";
import PropTypes from "prop-types";
import styles from "./styles/header.module.css";

export function CartDrawerHeader({ subtotal = null, handleCloseDrawer }) {
  const { t } = useTranslation("cart");
  let title = null;
  if (subtotal) {
    title = <span>: {subtotal}</span>;
  }
  return (
    <header className={styles.header}>
      {subtotal && (
        <h3 className={styles.title}>
          {t("header-title")}
          {title}
        </h3>
      )}
      <a className={styles.close} onClick={handleCloseDrawer}>
        {t("close-cart")}
      </a>
    </header>
  );
}

CartDrawerHeader.propTypes = {
  subtotal: PropTypes.string,
  handleCloseDrawer: PropTypes.func.isRequired,
};
