import PropTypes from "prop-types";
import Link from "next/link";
import { debounce } from "debounce";
import styles from "./styles/lineitem.module.css";

export function Linkable({ children, query, handleCloseDrawer }) {
  if (query && query.slug) {
    return (
      <Link
        href={{
          pathname: "/products/[slug]",
          query,
        }}
      >
        <a className={styles.link} onClick={debounce(handleCloseDrawer, 1000)}>
          {children}
        </a>
      </Link>
    );
  }
  return children;
}

Linkable.propTypes = {
  children: PropTypes.node.isRequired,
  query: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    colour: PropTypes.string,
    size: PropTypes.string,
  }),
};
