import {
  CurrencyProvider,
  MetafieldsProvider,
  CheckoutProvider,
  AccountProvider,
  KotnSupplyQuoteFormDrawerProvider
} from "@hooks/index";
import { Compose } from "./Compose";

export function ContextProviders({ children }) {
  return (
    <Compose
      components={[
        CurrencyProvider,
        MetafieldsProvider,
        CheckoutProvider,
        AccountProvider,
        KotnSupplyQuoteFormDrawerProvider
      ]}
    >
      {children}
    </Compose>
  );
}
