import { useTranslation } from "next-i18next";
import PropTypes from "prop-types";
import { getColor } from "../../Product/lib/product-utils";
import styles from "./styles/lineitem.module.css";

export function Colour({ variant }) {
  const { t } = useTranslation("cart");
  const colour = getColor(variant);

  if (colour) {
    return (
      <li className={styles.listItem}>
        <span>{t("colour-label")}:</span>
        <span>{colour}</span>
      </li>
    );
  }

  return null;
}

Colour.propTypes = {
  variant: PropTypes.object.isRequired,
};
