import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import PropTypes from "prop-types";
import { OpenIcon } from "./icons/OpenIcon";
import { resolveColour } from "./lib/resolve-colour";
import { resolveColours } from "./lib/resolve-colours";
import { resolveSizes } from "./lib/resolve-sizes";
import { formatTitle } from "@lib/formatString";
import styles from "./ProductCard.module.css";

const PRODUCTS_WITH_PRICE_RANGE = ["Duvet Set", "Sheet Set", "Flat Sheet"];

export const ProductCardFooter = ({
  product,
  price,
  cartIsLoading,
  salePrice,
  showQuickAdd,
  toggleQuickAdd,
  selectedColour,
  setSelectedColour,
  selectedSize,
  setSelectedSize,
  selectedProduct,
  listPosition,
  selectItem,
  query,
  openBlank,
  isMobile,
}) => {
  const router = useRouter();
  const colour = resolveColour(product.options);
  const colours = resolveColours(product);
  const sizes = resolveSizes(selectedProduct?.variants);

  const isFrench = router.locale === "fr";

  const sizeStyles = `
    ${styles.sizes}
    ${showQuickAdd ? styles.showSizes : ""}
  `;

  const toggleBtnStyles = `
    ${styles.toggleBtn}
    ${showQuickAdd ? styles.toggleBtnOpen : ""}
  `;

  const SizeButtons = () => {
    return sizes.map((item) => {
      const { size, available } = item;
      const isSelected = size === selectedSize;

      const sizeBtnStyles = `
          ${styles.y}
          ${!available ? styles.sizeBtnDisabled : ""}
          ${isSelected ? styles.sizeBtnSelected : ""}
        `;

      return (
        <li key={size}>
          <button
            className={sizeBtnStyles}
            disabled={!available}
            onClick={() => setSelectedSize(size)}
          >
            {size}
          </button>
        </li>
      );
    });
  };

  const Sizes = () => {
    const { t } = useTranslation("productcard");
    const sizeExists = sizes && sizes.find((size) => size.size);

    return (
      <>
        {sizeExists && (
          <div className={sizeStyles}>
            <div className={styles.sizeContainer}>
              {cartIsLoading ? (
                <p className={styles.sizeTitle}>{t("adding")}...</p>
              ) : (
                <>
                  <p className={styles.sizeTitle}>{t("quick")}:</p>
                  <ul className={styles.sizeList}>
                    <SizeButtons />
                  </ul>
                </>
              )}
            </div>
            <Link
              href={{
                pathname: "/products/[slug]",
                query,
              }}
              prefetch={false}
            >
              <a
                onClick={() => selectItem()}
                role="link"
                tabIndex={listPosition}
                className={styles.sizeTitle}
                target={openBlank && !isMobile ? "_blank" : ""}
              >
                {t("view")} &rarr;
              </a>
            </Link>
          </div>
        )}
      </>
    );
  };

  const ColourButtons = () => {
    return colours.map((item) => {
      const { hex, handle, id } = item;

      const title = isFrench && item.titleFr ? item.titleFr : item.title;

      const isSelected = selectedColour && selectedColour.id === id;

      const colourBtnStyles = `
          ${styles.colourBtn}
          ${isSelected ? styles.colourBtnSelected : ""}
        `;

      const borderStyles = `
          ${hex === "var(--color-white)" ? "1px solid var(--color-light-gray)" : "none"}
        `;

      return (
        <li key={id} className={styles.colourListItem}>
          <button
            className={colourBtnStyles}
            style={{
              backgroundColor: `${hex}`,
              border: borderStyles,
            }}
            onClick={() => setSelectedColour({ handle, id, hex, title })}
          />
        </li>
      );
    });
  };

  const Colours = () => {
    const { t } = useTranslation("productcard");
    const coloursStyles = `
      ${styles.colours}
      ${showQuickAdd ? styles.showColours : ""}
    `;

    const textStyles = `
      ${styles.text} ${styles.colourText}
      ${showQuickAdd ? styles.hideText : ""}
    `;

    return (
      <>
        <ul className={coloursStyles}>
          <ColourButtons />
        </ul>
        {colours.length > 0 && (
          <p className={textStyles}>
            {`${colours.length} ${colours.length === 1 ? t("colour") : t("colours")}`}
          </p>
        )}
      </>
    );
  };

  const Price = () => {
    const hasPriceRange = PRODUCTS_WITH_PRICE_RANGE.includes(product.productType);
    const priceValue = price ? price : null;
    const priceMessage = hasPriceRange ? `From ${priceValue}` : priceValue;

    return (
      <div className={styles.price}>
        <p className={styles.text}>{priceMessage}</p>
        {salePrice && !showQuickAdd && <p className={styles.text}>{salePrice}</p>}
      </div>
    );
  };

  const Title = () => {
    const { t } = useTranslation("productcard");
    const titleEn = product.sanity.title;
    const titleFr = product.sanity.title_fr;
    const title = isFrench && titleFr ? titleFr : formatTitle(titleEn);

    // If there is no colour (ex. candles or perfume)
    // shopify returns "0" for some reason
    if (colour === "0") {
      return <h2 className={styles.title}>{title}</h2>;
    }

    if (colour) {
      return (
        <h2 className={styles.title}>
          {`${title} ${t("in")} ${selectedColour ? selectedColour.title : colour}`}
        </h2>
      );
    }

    return <h2 className={styles.title}>{title}</h2>;
  };

  const Details = () => {
    return (
      <div className={styles.content}>
        <Title />
        <div className={styles.details}>
          <Price />
          <Colours />
        </div>
      </div>
    );
  };

  const ToggleButton = () => {
    return (
      <button onClick={toggleQuickAdd} className={toggleBtnStyles}>
        <OpenIcon />
      </button>
    );
  };

  return (
    <div className={styles.footer}>
      <Sizes />
      <div className={styles.innerContainer}>
        <Details />
        <ToggleButton />
      </div>
    </div>
  );
};

ProductCardFooter.propTypes = {
  product: PropTypes.shape({
    availableForSale: PropTypes.bool.isRequired,
    handle: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    images: PropTypes.array,
    options: PropTypes.array,
    sanity: PropTypes.object,
    tags: PropTypes.array,
    type: PropTypes.string,
    variants: PropTypes.array.isRequired,
    url: PropTypes.object,
  }).isRequired,
  price: PropTypes.string,
  salePrice: PropTypes.string,
  showQuickAdd: PropTypes.bool,
  toggleQuickAdd: PropTypes.func,
  selectedColour: PropTypes.shape({
    hex: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  setSelectedColour: PropTypes.func,
  selectedSize: PropTypes.string,
  setSelectedSize: PropTypes.func,
  selectedProduct: PropTypes.shape({
    availableForSale: PropTypes.bool.isRequired,
    handle: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    images: PropTypes.array,
    options: PropTypes.array,
    sanity: PropTypes.object,
    tags: PropTypes.array,
    type: PropTypes.string,
    variants: PropTypes.array.isRequired,
    url: PropTypes.object,
  }),
  listPosition: PropTypes.number,
  selectItem: PropTypes.func,
  link: PropTypes.shape({
    href: PropTypes.string,
    navigation: PropTypes.shape({
      colour: PropTypes.string,
    }),
    path: PropTypes.string,
    slug: PropTypes.shape({
      _type: PropTypes.string,
      current: PropTypes.string,
    }),
    text: PropTypes.string,
  }),
  query: PropTypes.shape({
    collection: PropTypes.string,
    colour: PropTypes.string,
    slug: PropTypes.string,
  }),
};
